import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/css/Nav.module.css";
import imageLogo from "../../styles/images/logo.png";
import imageLogoutBtn from "../../styles/images/logout_btn.png";
import imageGradeVip from "../../styles/images/grade-vip.png";
import imageWonIcon from "../../styles/images/won-icon.png";
import imageCalendarIcon from "../../styles/images/calendar-icon.png";
import imagePostIcon from "../../styles/images/post-icon.png";
import imageSelectedPostIcon from "../../styles/images/selected-post-icon.png";
import imageChevronRight from "../../styles/images/chevron-right.png";
import imageLeftArrowIcon from "../../styles/images/left-arrow-icon.png";
import imageSelectedScoreIcon from "../../styles/images/selected-score-icon.png";
import imageScoreIcon from "../../styles/images/score-icon.png";
import imageChat from "../../styles/images/chat-img.png";
import imageLoginBtn from "../../styles/images/login_btn.png";
import responsiveStyles from "../../styles/css/responsive.module.css";
import globalStyles from "../../styles/css/global.module.css";
import {getUserData} from "../../common/SaudiApi";

const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({
    userName: "사용자 이름",
    userLevel: "회원 등급",
    balance: "0",
    expiryDate: "유료 만료일",
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menuClosing, setMenuClosing] = useState(false);
  const [userName, setUserName] = useState("-");

  useEffect(() => {
    getUserDate()
  },[]);

  // 초기화 설정
  const getUserDate = async () => {
    try {
      const response = await getUserData({
        headers: {
          "Accept-Charset": "utf-8",
          "Content-Type": "application/json; charset=utf-8",
        }
      });
      setUserName(response.data);
    } catch (error) {
      console.error("Error:", error);
      alert("작업 중 오류가 발생했습니다.");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUserInfo = {
      userName: localStorage.getItem("userName") || "사용자 이름",
      userLevel: localStorage.getItem("userLevel") || "회원 등급",
      balance: localStorage.getItem("balance") || "0",
      expiryDate: localStorage.getItem("expiryDate") || "유료 만료일",
    };

    if (token) {
      setIsLoggedIn(true);
      setUserInfo(storedUserInfo);
    } else {
      setIsLoggedIn(false);
    }

    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (!mobile) setMenuOpen(false);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUserNameClick = () => {
    navigate("/mypage");
  };

  const toggleMenu = () => {
    if (menuOpen) {
      setMenuClosing(true);
      setTimeout(() => {
        setMenuOpen(false);
        setMenuClosing(false);
      }, 300);
    } else {
      setMenuOpen(true);
    }
  };

  const isActivePath = (path) => location.pathname === path;

  const NavItems = ({ onClick }) => (
      <aside className={globalStyles.aside}>
        <div className={`${globalStyles.asideHeader} ${globalStyles.boxShadow} ${globalStyles.boxSpace}`}>
          <a href="/home">
            <img src={imageLogo} alt="logo"/>
          </a>
          <a href="/">
            <img src={imageLogoutBtn} alt="logout_btn"/>
          </a>
        </div>

        <div className={`${globalStyles.asideGrade} ${globalStyles.boxShadowOrg} ${globalStyles.boxSpace}`}>
          <div className={globalStyles.asideGradeHeader}>
            <img src={imageGradeVip} alt="grade-vip"/>
            <p><strong>{userName}</strong>님, 환영해요! 😊</p>
          </div>

          <div className={globalStyles.asideGradeContents}>
            <div>
              <img src={imageWonIcon} alt="won-icon"/>
              <p className={globalStyles.asideGradeTitle}>충전캐시</p>
              <p className={globalStyles.asideGradeText}>30,874원</p>
            </div>

            <div>
              <img src={imageCalendarIcon} alt="calendar-icon"/>
              <p className={globalStyles.asideGradeTitle}>유료만료일</p>
              <p className={globalStyles.asideGradeText}>2025-03-22</p>
            </div>
          </div>
        </div>

        <div className="aside__kakao box-space">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img src={imageChat} alt="chat-img"/>
          </a>
        </div>

        <nav className={`${globalStyles.asideNav} ${globalStyles.boxShadowOrg}`}>
          <ul>
            <li>
              <div className={globalStyles.asideNavTitleBoxOrg}>
                <img src={
                  isActivePath("/write/auto")
                      ? imageSelectedPostIcon
                      : imagePostIcon
                } alt="post-icon"/>
                <p className={
                    isActivePath("/write/auto")
                        ? globalStyles.selectedNav
                        : ""
                  }>블로그 글쓰기</p>
              </div>
              <div className={globalStyles.asideNavLinkBox}>
                <NavLink
                    to="/write/auto"
                    className={
                      isActivePath("/write/auto")
                          ? globalStyles.selectedNav
                          : globalStyles.asideNavLinkBoxOrg
                    }
                    onClick={onClick}
                >
                  자동 글쓰기
                </NavLink>
              </div>
            </li>

            <li>
              <div className={globalStyles.asideNavTitleBoxOrg}>
                <img src={
                  isActivePath("/ranking/blog")
                      ? imageSelectedScoreIcon
                      : imageScoreIcon
                } alt="post-icon"/>
                <p className={
                  isActivePath("/ranking/blog")
                      ? globalStyles.selectedNav
                      : ""
                }>순위 보여주기</p>
              </div>
              <div className={globalStyles.asideNavLinkBox}>
                <NavLink
                    to="/ranking/blog"
                    className={
                      isActivePath("/ranking/blog")
                          ? globalStyles.selectedNav
                          : globalStyles.asideNavLinkBoxOrg
                    }
                    onClick={onClick}
                >
                  쇼핑, 플레이스, 블로그
                </NavLink>
              </div>
            </li>
          </ul>
        </nav>
      </aside>
  );


  const MobileHeader = ({onClick}) => {
    return (
        <div className="mHeader">
          <NavLink
              to="/"
              onClick={onClick}
          >
            <img src={imageLeftArrowIcon} alt="left-arrow-icon"/>
          </NavLink>
          <h2>순위 보여주기</h2>
          <h3 className={globalStyles.hidden}></h3>
        </div>
    );
  };

  const MobileHamburgerMenu = ({onClick}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const hamburgerMenuRef = useRef(null);

    const toggleHamburgerMenu = (forceClose = false) => {
      const body = document.body;

      if (isMenuOpen || forceClose) {
        body.classList.remove("no-scroll");
        hamburgerMenuRef.current.classList.remove("slide-in");
        hamburgerMenuRef.current.classList.add("slide-out");

        const handleTransitionEnd = () => {
          hamburgerMenuRef.current.style.display = "none";
          hamburgerMenuRef.current.classList.remove("slide-out");
          hamburgerMenuRef.current.removeEventListener("transitionend", handleTransitionEnd);
        };

        hamburgerMenuRef.current.addEventListener("transitionend", handleTransitionEnd, {
          once: true,
        });
        setIsMenuOpen(false);
      } else {
        body.classList.add("no-scroll");
        hamburgerMenuRef.current.style.display = "block";

        requestAnimationFrame(() => {
          hamburgerMenuRef.current.classList.remove("slide-out");
          hamburgerMenuRef.current.classList.add("slide-in");
        });
        setIsMenuOpen(true);
      }
    };

    return (
        <div ref={hamburgerMenuRef} className={globalStyles.mHamburger}>
          <div className={responsiveStyles.mHeader}>
            <NavLink to="/" onClick={onClick}> <img src={imageLeftArrowIcon} alt="left-arrow-icon"/> </NavLink>
            <h2>MENU</h2>
            <h3 className={globalStyles.hidden}></h3>
          </div>

          <div className={globalStyles.menuContent}>
            <div className={globalStyles.loginWrapper}>
              <h3>
                로그인 해주세요
                <img src={imageChevronRight} alt="chevron-right" />
              </h3>
              <img src={imageLoginBtn} alt="login_btn" />
            </div>

            <div className={globalStyles.gradientWrapper}>
              <button className={globalStyles.startBtn}>
                <span>차별화된 자동화 마케팅을 느껴보세요</span>
              </button>
            </div>

            <nav className={`${globalStyles.asideNav} ${globalStyles.boxShadow}`}>
              <ul>
                <li>
                  <div className={globalStyles.asideNavTitleBox}>
                    <img src={
                      isActivePath("/write/auto")
                          ? imageSelectedPostIcon
                          : imagePostIcon
                    } alt="post-icon"/>
                    <p>블로그 글쓰기</p>
                  </div>
                  <div className={globalStyles.asideNavLinkBox}>
                    <span>ㄴ</span>
                    <NavLink
                      to="/write/auto"
                      className={
                        isActivePath("/write/auto")
                            ? globalStyles.selectedNav
                            : styles.basicStyle
                      }
                      onClick={onClick}
                    >
                      자동 글쓰기
                    </NavLink>
                  </div>
                </li>

                <li>
                  <div className={globalStyles.asideNavTitleBox}>
                    <img src={
                      isActivePath("/ranking/blog")
                          ? imageSelectedScoreIcon
                          : imageScoreIcon
                    } alt="post-icon"/>
                    <p>순위 보여주기</p>
                  </div>
                  <div className={globalStyles.asideNavLinkBox}>
                    <span>ㄴ</span>
                    <NavLink
                        to="/ranking/blog"
                        className={
                          isActivePath("/ranking/blog")
                              ? globalStyles.selectedNav
                              : styles.basicStyle
                        }
                        onClick={onClick}
                    >
                      쇼핑, 플레이스, 블로그
                    </NavLink>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
    );
  };

  return (
      <div className="wrapper subPage">
        <NavItems/>
        {/*<MobileHeader/>*/}
        {/*<MobileHamburgerMenu/>*/}
      </div>
  );
};

export default Nav;
