import { Routes as ReactRouters, Route, Outlet } from "react-router-dom";
import Login from "../pages/login/Login.jsx";
import Home from "../pages/main/Home.jsx";
import Nav from "../components/common/Nav.jsx";
import AutoWrite from "../pages/write/AutoWrite.jsx";
import BulkWrite from "../pages/write/BulkWrite.jsx";
import AutoUpload from "../pages/manage/AutoUpload.jsx";
import Neighbors from "../pages/manage/Neighbors.jsx";
import Shopping from "../pages/ranking/Shopping.jsx";
import Place from "../pages/ranking/Place.jsx";
import Blog from "../pages/ranking/CrawlingBox.jsx";
import ProtectedRoute from "./ProtectedRoute";
import Register from "../pages/login/Register";

export default function Routes() {

  return (
    <ReactRouters>
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/*" element={<ProtectedRoute><Layout /></ProtectedRoute>}>

        <Route path="home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />

        <Route path="write">
          <Route
            path="auto"
            element={
              <ProtectedRoute>
                <AutoWrite />
              </ProtectedRoute>
            }
          />
          {/*<Route path="bulk" element={*/}
          {/*  <ProtectedRoute>*/}
          {/*    <BulkWrite />*/}
          {/*  </ProtectedRoute>*/}
          {/*  } />*/}
        </Route>

        <Route path="manage">
          <Route path="auto-upload" element={
            <ProtectedRoute>
              <AutoUpload />
            </ProtectedRoute>
          } />
          <Route path="neighbors" element={
            <ProtectedRoute>
              <Neighbors />
            </ProtectedRoute>
          } />
        </Route>

        <Route path="ranking">
          <Route path="shopping" element={
            <ProtectedRoute>
              <Shopping />
            </ProtectedRoute>
          } />
          <Route path="place" element={
            <ProtectedRoute>
              <Place />
            </ProtectedRoute>
          } />
          <Route path="blog" element={
            <ProtectedRoute>
              <Blog />
            </ProtectedRoute>
          } />
        </Route>
      </Route>
    </ReactRouters>
  );
}

const Layout = () => {
  return (
    <div style={styles.layoutContainer}>
      <div style={styles.navContainer}>
        <Nav />
      </div>
      <div style={styles.contentContainer}>
        <Outlet />
      </div>
    </div>
  );
};

const styles = {
  layoutContainer: {
    display: "flex",
    height: "100vh",
    width: "100vw",
  },
  navContainer: {
    borderRight: "1px solid #ccc",
    backgroundColor: "#f9f9f9",
    overflowY: "auto",
  },
  contentContainer: {
    flex: "1",
    padding: "20px",
    overflowY: "auto",
    backgroundColor: "#fff",
  },
};
