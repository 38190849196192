import React from "react";
import styles from "../../styles/css/AutoWrite.module.css";

const InputRow = ({ label, type = "text", value, onChange, placeholder }) => (
  <div className={styles.row}>
    {label && <label>{label}</label>}
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={styles.input}
    />
  </div>
);

export default InputRow;
