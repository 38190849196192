import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    // 토큰이 없으면 로그인 페이지로 리다이렉트
    if (isAuthenticated === "false" || token === "null") {
        return <Navigate to="/" />;
    }

    // 토큰이 있으면 자식 컴포넌트 렌더링
    return children;
};

export default ProtectedRoute;
