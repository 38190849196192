import React, {useEffect, useState} from "react";
import globalStyles from "../../styles/css/global.module.css";
import rankCrawlingStyles from "../../styles/css/rank-crawling.module.css";
import {RANK_CRAWLING_TYPE} from "../../config/Constants";
import {getCrawlingList} from "../../common/SaudiApi";
import Pagination from "./Pagination";

const CrawlingBox = () => {

  const [selectedOption, setSelectedOption] = useState("");
  const [keywordGroup, setKeywordGroup] = useState("");
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [crawlingPage, setCrawlingPage] = useState({
    total:"",
    start:"",
    display:""
  });

  const [items, setItems] = useState([{
      title:"",
      link:"",
      description:"",
      bloggername:"",
      bloggerlink:"",
      roadAddress:"",
      productId:""
    }]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setItems(null)
  };

  const handleKeywordGroup = (event) => {
    setKeywordGroup(event.target.value);
  };

  const handleKeyword = (event) => {
    setKeyword(event.target.value);
  };

  // const handlePrintLastIdxTr = (item) => {
  //   if (selectedOption === "BLOG") {
  //     return "블로그명";
  //   } else if (selectedOption === "LOCAL") {
  //     return "주소";
  //   } else if (selectedOption === "SHOP") {
  //     return "-"; // 값이 없을 경우 기본값 반환
  //   }
  //   return "-";
  // };

  const handlePrintLastIdxTr = (item) => {
    if (selectedOption === "BLOG") {
      return "블로거 링크";
    } else if (selectedOption === "LOCAL") {
      return "주소";
    } else if (selectedOption === "SHOPPING") {
      return "쇼핑몰"; // 값이 없을 경우 기본값 반환
    }
    return "-";
  };

  const handlePrintLastIdxTd = (item) => {
    if (selectedOption === "BLOG") {
      return item.bloggerlink|| "-";
    } else if (selectedOption === "LOCAL") {
      return item.roadAddress|| "-";
    } else if (selectedOption === "SHOPPING") {
      return "-"; // 값이 없을 경우 기본값 반환
    }
    return "-";
  };

  const stripHtmlTags = (input) => {
    if (!input) return ""; // null 또는 undefined 처리
    return input.replace(/<\/?[^>]+(>|$)/g, ""); // 정규식으로 HTML 태그 제거
  };

  // 초기화 설정
  useEffect(() => {
    setSelectedOption("BLOG")
    setItems(null)
  }, []);

  const handleStartClick = async () => {
    try {
      if(keywordGroup == ""){
        alert("키워드 그룹을 입력하세요");
        return
      }
      if(keyword == ""){
        alert("키워드를 입력하세요");
        return
      }
      const response = await getCrawlingList({
        headers: {
          "Accept-Charset": "utf-8",
          "Content-Type": "application/json; charset=utf-8",
        },
        params:{
          searchType: selectedOption,
          group: (keywordGroup),
          keyword: (keyword),
        }
      });
      setItems(
          response.data.items.map((item) => ({
            title: stripHtmlTags(item.title),
            link: item.link,
            description: stripHtmlTags(item.description),
            bloggername: (item.bloggername),
            bloggerlink: (item.bloggerlink),
            roadAddress: (item.roadAddress),
            productId: (item.productId),
            mallName: (item.mallName),
          }))
      );
      setCrawlingPage({
        total: response.data.total,
        start: response.data.start,
        display: response.data.display,});
    } catch (error) {
      console.error("Error:", error);
      alert("작업 중 오류가 발생했습니다.");
    }
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > Math.ceil(crawlingPage.total / itemsPerPage)) return;
    setCurrentPage(newPage);
    setCrawlingPage((prev) => ({
      ...prev,
      start: (newPage - 1) * itemsPerPage + 1,
    }));
  };

  return (
      <div className={`${rankCrawlingStyles} ${globalStyles.boxShadowOrg}`}>
        <form className={globalStyles.searchForm} onSubmit={(e) => e.preventDefault()}>
          <select value={selectedOption} onChange={handleSelectChange}>
            {Object.entries(RANK_CRAWLING_TYPE).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
            ))}
          </select>
          <input
              type="text"
              placeholder="검색 키워드 그룹을 입력하세요"
              name="keyword"
              value={keywordGroup}
              onChange={handleKeywordGroup}
          />
          <input
              type="text"
              placeholder="검색 키워드를 입력하세요"
              name="urls"
              value={keyword}
              onChange={handleKeyword}
          />
          <div className={globalStyles.gradientWrapper}>
            <button className={globalStyles.startBtn} onClick={handleStartClick}>
              <span>자동작업 시작</span>
            </button>
          </div>
        </form>

        <div className={rankCrawlingStyles.highlightBox}>
          <ul>
            <li>• 플레이스 플레이스 플레이스</li>
            <li>• 쇼핑 쇼핑 쇼핑</li>
            <li>• 블로그 블로그 블로그</li>
            <li>• 쇼핑 쇼핑 쇼핑</li>
            <li>• 블로그 블로그 블로그</li>
          </ul>
        </div>

        <div className={rankCrawlingStyles.crawlingTableWrapper}>
          <table className={`${rankCrawlingStyles.crawlingTable} ${rankCrawlingStyles.cwt2}`}>
            <thead>
            <tr>
              {/*<th>순번(텍스트)</th>*/}
              {/*<th>항목(선택)</th>*/}
              {/*<th>그룹(텍스트)</th>*/}
              <th>검색어(텍스트)</th>
              <th>url 주소(텍스트)</th>
              <th>상품명(크롤링)</th>
              <th>메모(텍스트)</th>
              <th>{handlePrintLastIdxTr()}</th>
            </tr>
            </thead>
            <tbody>
            {items ? items.map((item, index) => (
                <tr key={index}>
                  <td>
                    <span>{crawlingPage.start + index}</span>
                  </td>
                  {/*<td>{selectedOption.valueOf()}</td>*/}
                  {/*<td>{keywordGroup}</td>*/}
                  {/*<td>{keyword}</td>*/}
                  <td><a href={item.link} style={{cursor: "pointer"}}>{item.link}</a></td>
                  <td>{item.title}</td>
                  {/*<div className={globalStyles.tooltip}>*/}
                  {/*  <span className={globalStyles.truncate}>*/}
                  {/*    {truncateText(item.description, 20)}*/}
                  {/*  </span>*/}
                  {/*      {item.description.length > 20 && (*/}
                  {/*          <span className={globalStyles.tooltipText}>{item.description}</span>*/}
                  {/*      )}*/}
                  {/*</div>*/}
                  <td>{truncateText(item.description, 30)}</td>
                  <td>{handlePrintLastIdxTd(item)}</td>
                </tr>
            )) : <tr></tr>}
            </tbody>
          </table>
          <Pagination
              totalItems={crawlingPage.total}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
          />
        </div>
      </div>
  );
};

export default CrawlingBox;
