import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {loginUser, createUser} from "../../common/SaudiApi";
import styles from "../../styles/css/Login.module.css";

const Login = () => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // 초기화 설정
  useEffect(() => {
    localStorage.setItem('token', null);
    localStorage.setItem('isAuthenticated', false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const loginData = {"username" : userId, "password" : password}
      const response = await loginUser(loginData);

      if (response.status === 200) {
        // 성공 처리
        setErrorMessage("");
        localStorage.setItem('token', response.data.id_token);
        localStorage.setItem('isAuthenticated', true);

        navigate("/home");
      }
    } catch (error) {
      // 에러 처리
      if (error.response && error.response.status === 401) {
        setErrorMessage("아이디 또는 비밀번호가 올바르지 않습니다.");
      } else {
        setErrorMessage("알 수 없는 오류가 발생했습니다. 다시 시도해주세요.");
      }
    }
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${require("../../styles/images/mainBasic.png")})`,
      }}
    >
      <div className={styles.imageSection} />
      <div className={styles.formSection}>
        <div className={styles.basicSection}>
          <h1>환영합니다! 👋🏻</h1>
          <p>차별화된 마케팅 서비스를 느껴보세요.</p>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <input
                type="text"
                placeholder="아이디"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type="password"
                placeholder="비밀번호"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {errorMessage && (
              <div className={styles.errorMessage}>{errorMessage}</div>
            )}
            <div className={styles.checkboxGroup}>
              <label>
                <input type="checkbox" /> 아이디 저장
              </label>
            </div>
            <button type="submit" className={styles.submitButton}>
              시작하기
            </button>
          </form>
          <p className={styles.forgotPassword}>
            <span
              onClick={() => navigate("/register")}
              style={{
                cursor: "pointer",
              }}
            >
              회원가입
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
