import React, {useEffect, useState} from "react";
import indexStyles from "../../styles/css/index.module.css";
import globalStyles from "../../styles/css/global.module.css";
import warning from "../../styles/images/warning.png";
import {getCrawlingList, getUserData} from "../../common/SaudiApi";

const Home = () => {
  const trendData = [
    {rank: 1, keyword: "김범수 꽃중년", change: "▲2", changeClass: "rate-up"},
    {rank: 2, keyword: "윤 대통령 양극화", change: "▼2", changeClass: "rate-down"},
    {rank: 3, keyword: "김정은 협상", change: "-", changeClass: ""},
    {rank: 4, keyword: "동덕여대 시위", change: "NEW", changeClass: "rate-new"},
    {rank: 5, keyword: "가상자산 과세", change: "NEW", changeClass: "rate-new"},
    {rank: 6, keyword: "비트코인", change: "NEW", changeClass: "rate-new"},
    {rank: 7, keyword: "오늘의 운세", change: "NEW", changeClass: "rate-new"},
    {rank: 8, keyword: "성용 개그맨", change: "NEW", changeClass: "rate-new"},
    {rank: 9, keyword: "지하철 파업", change: "NEW", changeClass: "rate-new"},
    {rank: 10, keyword: "한화솔루션", change: "NEW", changeClass: "rate-new"},
  ];



  return (

    <div className={`${indexStyles.trends} ${globalStyles.boxShadow}`}>
      <div className={indexStyles.trends__content}>
        <h1>실시간 검색어 트렌드</h1>

        <table className={indexStyles.trends__table}>
          <thead>
          <tr>
            <th>순위</th>
            <th>검색어</th>
            <th>
              <img src={warning} alt="warning"/> 2024-11-22
              11:15:03 기준
            </th>
          </tr>
          </thead>
          <tbody>
          {trendData.map((trend, index) => (
              <tr key={index}>
                <td>
                  <span >{trend.rank}</span>
                </td>
                <td>{trend.keyword}</td>
                <td className={`indexStyles.${trend.changeClass}`}>{trend.change}</td>
              </tr>
          ))}
          </tbody>
        </table>

        <div className={globalStyles.mTrendsAlert}>
          <img src={warning} alt="warning"/>
          <span>2024-11-22 11:15:03 기준</span>
        </div>
      </div>
    </div>
  );
};

export default Home;
