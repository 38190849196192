import React, { useState } from "react";
import axios from "axios";
import styles from "../../styles/css/AutoWrite.module.css";
import Section from "../../components/write/Section";
import InputRow from "../../components/write/InputRow";
import ToggleSwitch from "../../components/write/ToggleSwitch";
import FileUpload from "../../components/write/FileUpload";
import WriteStart from "../../styles/images/start-icon.png";
import keywordsData from "../../data/keywords.json";

const AutoWrite = () => {
  const [formData, setFormData] = useState({
    keyword: { type: "직접입력", input: "" },
    tone: { type: "직접입력", input: "" },
    emphasis: "",
    options: {
      searchInternet: false,
      reprocessImages: false,
      randomWatermark: false,
      watermarkText: "",
    },
    images: [],
  });

  const [generatedText, setGeneratedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);

  const getRandomKeyword = () => {
    const randomIndex = Math.floor(
      Math.random() * keywordsData.keywords.length
    );
    return keywordsData.keywords[randomIndex];
  };

  const updateFormData = (path, value) => {
    setFormData((prev) => {
      const keys = path.split(".");
      const lastKey = keys.pop();
      const nested = keys.reduce((obj, key) => obj[key], prev);
      nested[lastKey] = value;
      return { ...prev };
    });
  };

  const formatText = (text, lineLength = 80) => {
    const paragraphs = text
      .split("\n")
      .map((p) => p.trim())
      .filter(Boolean);

    const formattedParagraphs = paragraphs.map((paragraph) => {
      const words = paragraph.split(" ");
      let currentLine = "";
      let formatted = "";

      words.forEach((word) => {
        if ((currentLine + word).length > lineLength) {
          formatted += currentLine.trim() + "\n";
          currentLine = word + " ";
        } else {
          currentLine += word + " ";
        }
      });

      if (currentLine.trim()) {
        formatted += currentLine.trim();
      }

      return formatted;
    });

    return formattedParagraphs.join("\n\n");
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);

    if (formData.images.length + files.length > 100) {
      alert("최대 100개의 이미지만 업로드할 수 있습니다.");
      return;
    }
    if (files.length > 30) {
      alert("한 번에 최대 30개의 이미지만 업로드할 수 있습니다.");
      return;
    }

    const filePreviews = files.map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
    }));

    setImagePreviews((prev) => [...prev, ...filePreviews]);

    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...files],
    }));
  };

  const handleImageDelete = (index) => {
    setImagePreviews((prev) => prev.filter((_, i) => i !== index));
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setGeneratedText("");

    try {
      const { keyword, tone, emphasis } = formData;

      const messages = [
        { role: "system", content: "You are a skilled writer and assistant." },
        {
          role: "user",
          content: `
            다음 조건에 따라 글을 작성해주세요:
            - 제목은 40자 이내로 간결하게 작성합니다.
            - 본문은 공백 포함 2650자~3450자 사이로 작성합니다.
            - 내용에 줄바꿈 등을 사용하여서 사용자가 읽기 편한 형태로 작성하세요.
            - 각 문단은 2~5문장으로 구성하며, 줄바꿈으로 명확히 구분해주세요.
            - 주요 키워드는 "${keyword.input}"이며, 문서의 맨 앞에 배치하세요.
            - 독자의 호기심을 자극하는 문구를 삽입하세요.
            - 강조할 내용은 "${emphasis}"입니다.
            - 글은 "${tone.input || tone.type}" 말투로 작성해주세요.
          `,
        },
      ];

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-mini",
          messages,
          max_tokens: 4000,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const rawText = response.data.choices[0].message.content.trim();
      setGeneratedText(formatText(rawText));
    } catch (error) {
      console.error("API 호출 실패:", error.response?.data || error.message);
      setGeneratedText(
        "글 작성 중 오류가 발생했습니다. 설정을 확인하고 다시 시도해주세요."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftPane}>
        <Section title="자동으로 글을 작성하세요!">
          <div className={styles.row}>
            {["실시간검색어", "추천키워드", "직접입력"].map((type) => (
              <button
                key={type}
                className={`${styles.button} ${
                  formData.keyword.type === type ? styles.activeButton : ""
                }`}
                onClick={() => {
                  updateFormData("keyword.type", type);
                  if (type === "추천키워드") {
                    const randomKeyword = getRandomKeyword();
                    updateFormData("keyword.input", randomKeyword);
                  } else if (type !== "직접입력") {
                    updateFormData("keyword.input", type);
                  } else {
                    updateFormData("keyword.input", "");
                  }
                }}
              >
                {type}
              </button>
            ))}
          </div>
          {formData.keyword.type === "직접입력" && (
            <InputRow
              placeholder="주제를 입력하세요"
              value={formData.keyword.input}
              onChange={(e) => updateFormData("keyword.input", e.target.value)}
            />
          )}
        </Section>
        <Section title="나만의 말투를 선택하세요">
          <div className={styles.row}>
            {["습니다체", "해요체", "직접입력"].map((type) => (
              <button
                key={type}
                className={`${styles.button} ${
                  formData.tone.type === type ? styles.activeButton : ""
                }`}
                onClick={() => {
                  updateFormData("tone.type", type);
                  if (type !== "직접입력") {
                    updateFormData("tone.input", type);
                  } else {
                    updateFormData("tone.input", "");
                  }
                }}
              >
                {type}
              </button>
            ))}
          </div>
          {formData.tone.type === "직접입력" && (
            <InputRow
              placeholder="말투를 입력하세요"
              value={formData.tone.input}
              onChange={(e) => updateFormData("tone.input", e.target.value)}
            />
          )}
        </Section>
        <Section title="강조하고 싶은 핵심내용을 입력하세요">
          <InputRow
            placeholder="줄거리, 가격, 지도추가, 기타 요구사항 등"
            value={formData.emphasis}
            onChange={(e) => updateFormData("emphasis", e.target.value)}
          />
        </Section>

        {/* ToDo: 추후 스위치 컴포넌트 / 사진 첨부 정교하게 개발
        <Section>
          <div className={styles.gridSection}>
            <ToggleSwitch
              label="인터넷에서 검색할게요."
              checked={formData.options.searchInternet}
              onChange={(e) =>
                updateFormData("options.searchInternet", e.target.checked)
              }
            />
            <ToggleSwitch
              label="이미지를 재가공할게요."
              checked={formData.options.reprocessImages}
              onChange={(e) =>
                updateFormData("options.reprocessImages", e.target.checked)
              }
            />
            <ToggleSwitch
              label="랜덤위치 워터마크 추가"
              checked={formData.options.randomWatermark}
              onChange={(e) =>
                updateFormData("options.randomWatermark", e.target.checked)
              }
            />
          </div>
          <InputRow
            placeholder="워터마크 내용"
            value={formData.options.watermarkText}
            onChange={(e) =>
              updateFormData("options.watermarkText", e.target.value)
            }
          />
        </Section> */}

        {/* <FileUpload onUpload={handleFileUpload} /> */}

        {/* 
        <h1>이미지 미리보기</h1>
        <div className={styles.imagePreviewContainer}>
          {imagePreviews.length > 0 ? (
            imagePreviews.map((preview, index) => (
              <div key={index} className={styles.imagePreview}>
                <img src={preview.url} alt={preview.name} />
                <button
                  className={styles.deleteButton}
                  onClick={() => handleImageDelete(index)}
                >
                  삭제
                </button>
              </div>
            ))
          ) : (
            <p>이미지 없음</p>
          )}
        </div> */}
        <button className={styles.submitButton} onClick={handleSubmit}>
          {loading ? (
            "작성 중..."
          ) : (
            <>
              <img
                src={WriteStart}
                alt="시작하기 아이콘"
                className={styles.buttonIcon}
              />
              시작하기
            </>
          )}
        </button>
      </div>

      <div className={styles.rightPane}>
        <h1>작성된 글</h1>
        {loading ? (
          <p>글 작성 중입니다. 잠시만 기다려주세요...</p>
        ) : (
          <div className={styles.output}>
            {generatedText
              ? generatedText.split("\n\n").map((paragraph, index) => (
                  <p
                    key={index}
                    style={{ marginBottom: "2rem", textAlign: "justify" }}
                  >
                    {paragraph.split("\n").map((line, lineIndex) => (
                      <span key={lineIndex}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </p>
                ))
              : "아직 작성된 글이 없습니다."}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoWrite;
