import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {loginUser, createUser} from "../../common/SaudiApi";
import styles from "../../styles/css/Login.module.css";

const Register = () => {
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);

  const [match, setMatch] = useState(true);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setMatch(password === e.target.value);
  };

  // 초기화 설정
  useEffect(() => {
    localStorage.setItem('token', null);
    localStorage.setItem('isAuthenticated', false);
    setPassword("");
    setConfirmPassword("");
    setLogin("");
  }, []);

  const handleLoginChange = (e) => {
    const value = e.target.value;
    setLogin(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // 간단한 이메일 유효성 검사
    const emailRegex = /^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/;
    setIsValid(emailRegex.test(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const registerData = {"login" : login, "password" : password, "email" : email}
      const response = await createUser(registerData);

      if (response.status === 201) {
        // 성공 처리
        setErrorMessage("");

        navigate("/");
      }
    } catch (error) {
      // 에러 처리
      if(error.status === 400 ){
        setErrorMessage("이미 가입된 이메일입니다.");
      } else{
        setErrorMessage("알 수 없는 오류가 발생했습니다. 다시 시도해주세요.");
      }
    }
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${require("../../styles/images/mainBasic.png")})`,
      }}
    >
      <div className={styles.imageSection} />
      <div className={styles.formSection}>
        <div className={styles.basicSection}>
          <h1>환영합니다! 👋🏻</h1>
          <p>차별화된 마케팅 서비스를 느껴보세요.</p>
          <form onSubmit={handleSubmit}>
            <div style={{maxWidth: "400px", marginTop: "10px", margin: "auto", textAlign: "center"}}>
              <div className={styles.inputGroup}>
                <input
                    type="text"
                    value={login}
                    onChange={handleLoginChange}
                    placeholder="아이디"
                />
              </div>
              <div className={styles.inputGroup}>
                <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="이메일"
                />
              </div>
              <div className={styles.inputGroup}>
                <input
                    type="password"
                    placeholder="비밀번호"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                />
              </div>
              <div className={styles.inputGroup}>
                <input
                    type="password"
                    placeholder="비밀번호 확인"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                />
              </div>
              {!match && (
                  <p className={styles.errorMessage} style={{marginTop: "10px"}}>
                    비밀번호가 일치하지 않습니다.
                  </p>
              )}
              {errorMessage && (
                  <div className={styles.errorMessage}>{errorMessage}</div>
              )}

              <button type="submit" className={styles.submitButton}>
                회원가입
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
