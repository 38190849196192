import React from "react";
import styles from "../../styles/css/AutoWrite.module.css";

const Section = ({ title, children }) => (
  <div className={styles.section}>
    <h1>{title}</h1>
    {children}
  </div>
);

export default Section;
