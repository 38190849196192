import React, { useState } from "react";
import globalStyles from "../../styles/css/global.module.css";// 별도의 CSS 파일 추가

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 현재 페이지 주변의 페이지 번호를 계산
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // 한 번에 보여줄 페이지 번호 수
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
      <div className={globalStyles.pagination}>
        <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-btn"
        >
          이전
        </button>

        {/* 페이지 번호 */}
        {getPageNumbers().map((page) => (
            <button
                key={page}
                onClick={() => onPageChange(page)}
                className={`pagination-btn ${
                    currentPage === page ? "active" : ""
                }`}
            >
              {page}
            </button>
        ))}

        {/* 마지막 페이지로 이동 */}
        {currentPage < totalPages - 1 && (
            <>
              <span className="pagination-dots">...</span>
              <button
                  onClick={() => onPageChange(totalPages)}
                  className="pagination-btn"
              >
                {totalPages}
              </button>
            </>
        )}

        <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-btn"
        >
          다음
        </button>
      </div>
  );
};

export default Pagination;
