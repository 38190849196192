import axios from 'axios';

// Axios 인스턴스 생성
const saudiAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // 공통적으로 사용할 API의 기본 URL
    timeout: 10000, // 요청 타임아웃 설정 (10초)
});

// 요청(Request) Interceptor 설정
saudiAxios.interceptors.request.use(
    (config) => {
        // 공통적으로 요청에 포함할 헤더 추가
        const token = localStorage.getItem('token'); // 예: 토큰 가져오기
        if (token !== "null") {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        console.log('Request:', config);
        return config;
    },
    (error) => {
        // 요청 오류 처리
        console.error('Request Error:', error);
        return Promise.reject(error);
    }
);

// 응답(Response) Interceptor 설정
saudiAxios.interceptors.response.use(
    (response) => {
        // 응답 데이터 공통 처리
        console.log('Response:', response);
        return response;
    },
    (error) => {
        // 응답 오류 처리
        console.error('Response Error:', error);

        if (error.response) {
            const { status } = error.response;
            // 예: 토큰 만료 처리
            if (status === 401) {
                alert('로그인이 만료되었습니다. 다시 로그인해주세요.');
                window.location.href = '/';
                localStorage.setItem('token', null);
                localStorage.setItem('isAuthenticated', false);
            }
        }
        return Promise.reject(error);
    }
);

export default saudiAxios;
